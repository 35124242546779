import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
function Footer() {
    const [categories, setCategories] = useState([]);
    function category() {
        fetch('https://api.magic20.co.in/LandingPage/GetCategories').then((respons) => {
            respons.json().then((result) => {
                setCategories(result.data);
            })
        });
    }
    useEffect(() => {
        category()
    }, [])
    return (<>
        <div className="App">
            <section className="appie-footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget footer-about-widget-2">
                                <div className="logo">
                                    <a href="!#"><img src="/assets/images/fav.png" alt="" style={{ mixBlendMode: "darken", }} /></a>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation footer-navigation-2">
                                <h4 className="title">Categories</h4>
                                {<ul>
                                    {
                                        categories.map((item) =>
                                            <li key={item._id} style={{ color: '#505056', fontSize: '15px', fontWeight: '500', marginBottom: '6px' }}>{item.name}</li>

                                        )
                                    }

                                </ul>}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation footer-navigation-2 footer-about-widget" style={{ paddingRight: '50px' }}>
                                <h4 className="title">Important Links</h4>
                                <ul>

                                    <li><Link to='privacypolicy'>Privacy Policy</Link></li>
                                    <li><Link to='Terms&Conditions'>Terms & Conditions</Link></li>
                                    <li><Link to='RefundAndCancellation'>Refund & Cancellation Policy</Link></li>
                                </ul>
                                <div className="social mt-30">
                                    <ul>
                                        <li><a target='_blank' rel="noreferrer" href="https://www.facebook.com/profile.php?id=100083953600943"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a target='_blank' rel="noreferrer" href="https://twitter.com/Booksparkmedia"><i className="fab fa-twitter"></i></a></li>
                                        <li><a target='_blank' rel="noreferrer" href="https://www.instagram.com/magic20tamil/"><i class="fab fa-instagram"></i></a></li>
                                        <li><a target='_blank' rel="noreferrer" href="https://www.linkedin.com/company/magic20tamil/"><i className="fab fa-linkedin-in"></i></a></li>
                                        <li><a target='_blank' rel="noreferrer" href="https://www.youtube.com/channel/UCdo4QKM17FSjguMv2aXQQYg/"><i className="fab fa-youtube"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Get In Touch</h4>
                                <ul>
                                    <li><a href="mailto:support@magic20.co.in"><i className="fal fa-envelope"></i> support@magic20.co.in</a></li>
                                    <li><a href="tel:9176963399"><i className="fal fa-phone"></i> +91 91769 63399</a></li>
                                    <li><a href="https://goo.gl/maps/8RiX9ikdFJDaRRYc8" target='_blank' rel="noreferrer"><i className="fal fa-map-marker-alt"></i> Bookspark Media Pvt Ltd,4th Floor, 37/9 Hariram Building,  College road, Chennai 600006</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li><a href="https://apps.apple.com/in/app/magic-20-tamil/id6444919423"><i className="fab fa-apple"></i> Download for iOS</a></li>
                                        <li><a className="item-2" href="https://play.google.com/store/apps/details?id=com.app.puthagam"><i className="fab fa-google-play"></i> Download for Android</a></li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>Copyright © 2023 MagicTamil. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>);
}

export default Footer;